import React from 'react';
import { Col, Layout, Row, Typography } from 'antd';
import Top from '../Top';
import Info from '../Info';
import NewMsg from '../NewMsg';
import ChangePwd from '../ChangePwd';

function BaseView(props) {
  const { children, name } = props;
  const span = { span: 24 };
  const gutter = [32, 32];
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Layout.Header>
        <div className='logo' />
        <Typography.Title style={{ color: '#fff' }}>
          Survey Center - {name}
          <ChangePwd />
        </Typography.Title>
      </Layout.Header>
      <Layout.Content style={{ padding: '0 50px' }}>
        <div
          style={{
            minHeight: '320px',
            padding: '24px',
            background: '#fff',
          }}
        >
          <Row gutter={gutter}>
            <Col {...span} lg={10}>
              <Row gutter={gutter}>
                <Col span={24}>
                  <Info name={name} />
                </Col>
                <Col span={24}>
                  <NewMsg />
                </Col>
              </Row>
            </Col>
            <Col {...span} lg={14}>
              <Row gutter={gutter}>
                <Col span={24} key={-1}>
                  <Top />
                </Col>
                {Array.isArray(children) ? (
                  children.map((item, index) => (
                    <Col span={24} key={index}>
                      {item}
                    </Col>
                  ))
                ) : (
                  <Col span={24} key={-2}>
                    {children}
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </div>
      </Layout.Content>
      <Layout.Footer style={{ textAlign: 'center' }}>
        Survey Center ©2021
      </Layout.Footer>
    </Layout>
  );
}
export default BaseView;
